// Styling for the footer
footer {
  padding: 50px 0 65px;
  .list-inline {
    margin: 0;
    padding: 0;
  }
  .copyright {
    font-size: 14px;
    margin-bottom: 0;
    text-align: center;
  }
}
